import React from 'react';
import Layout from 'components/Layout';
import { Router } from '@reach/router';
import { ReactQueryConfigProvider } from 'react-query';
import Retailer from 'components/Retailer';

export default function planProfile(props) {
  return (
    <Layout {...props} hideFilters>
      <ReactQueryConfigProvider
        config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
      >
        <Router>
          <Retailer path="/retailer/:retailerId" />
        </Router>
      </ReactQueryConfigProvider>
    </Layout>
  );
}
