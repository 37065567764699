import { Avatar, Grid, LinearProgress, Typography } from '@material-ui/core';
import NavigationIcon from '@material-ui/icons/Navigation';
import React from 'react';
import { useQuery } from 'react-query';
import fetch from 'utils/fetch';
import { truncate } from 'utils/utils';

export default function Retailer({ retailerId }) {
  const { data } = useQuery(['retailers', { retailerId }], () =>
    fetch(`/v3/retailer/${retailerId}`)
  );
  if (!data) return <LinearProgress style={{ width: '100%' }} />;
  const { name, images, code, extraFields, channel, phone } = data;

  return (
    <Grid
      container
      columns={1}
      direction="column"
      wrap="wrap"
      spacing={2}
      className="justify-center items-center my-4"
    >
      <Grid
        item
        className="xs:w-full md:w-1/2 lg:w-1/3 shadow rounded-lg flex justify-between p-2 items-center"
      >
        <div className="flex justify-between items-center">
          {images[0] && (
            <Avatar variant="square" style={{ height: 100, width: 130 }} src={images[0]} />
          )}
          <div className="ml-3">
            <Typography className="font-bold">{truncate(code)}</Typography>
            <p>{name}</p>
          </div>
        </div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.google.com/maps?daddr= ${data.latitude} , ${data.longitude}`}
        >
          <NavigationIcon className="mr-10 cursor-pointer" color="primary" />
        </a>
      </Grid>
      {Object.entries(extraFields).map((field) => (
        <Grid item key={field[0]} className="flex items-center">
          <p className="font-bold mr-2">{field[0]}</p>
          <p>{field[1]}</p>
        </Grid>
      ))}
      <Grid item className="flex items-center">
        <p className="font-bold mr-2">Phone</p>
        <p>{phone}</p>
      </Grid>
      <Grid item className="flex items-center">
        <p className="font-bold mr-2">Channel</p>
        <p>{channel}</p>
      </Grid>
    </Grid>
  );
}
